import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBanner = makeShortcode("AuthorBanner");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBanner mdxType="AuthorBanner" />
    <p>&nbsp;</p>
    <h3>A little about me...</h3>
    <p>{`Hi! I'm a Software Engineering student at McGill University, graduating in May 2021 :) I like building cool things with JavaScript and React and am exploring the web dev world! I also write monthly articles on dev.to and Medium. Checkout my publications!`}</p>
    <br />
I like reading stuff about the web dev industry, and am curious about anything related it. Although I am currently learning the core computer science/software engineering topics at McGill, most of the web-dev related skills that I have achieved are self-taught. I love the Web and I want to be able to contribute to it, which is why I hope to get a fulltime position in front-end related positions :)
    <h3>Skills</h3>
    <ul>
      <li parentName="ul">{`JAVASCRIPT`}</li>
      <li parentName="ul">{`HTML/CSS`}</li>
      <li parentName="ul">{`REACT`}</li>
      <li parentName="ul">{`ANGULAR`}</li>
      <li parentName="ul">{`REACT NATIVE`}</li>
      <li parentName="ul">{`REDUX`}</li>
      <li parentName="ul">{`GATSBY`}</li>
      <li parentName="ul">{`NETLIFY`}</li>
      <li parentName="ul">{`OCAML`}</li>
      <li parentName="ul">{`REASONREACT`}</li>
      <li parentName="ul">{`EXPRESS`}</li>
      <li parentName="ul">{`JAVA`}</li>
      <li parentName="ul">{`PYTHON`}</li>
      <li parentName="ul">{`SQL`}</li>
      <li parentName="ul">{`REST APIS`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      